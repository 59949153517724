<template>
  <div v-if="type === 'google'">
    <div class="card">
      <div class="title">
        <div class="caption">{{ $t('confirm_code_to_continue') }}</div>
      </div>
      <div class="content">
        <div style="color: #686E74">{{ $t('google.verify.content') }}</div>
        <div class="verificationBox mt-4">
          <div class="hint">
            <div style="display: inline-block">{{ $t('enter_code') }}</div>
            <div class="lost-code-box" @click="lost">
              {{ $t('lost_code') }}
            </div>
          </div>
          <div class="code">
            <CodeComponent ref="child" :attempt="attempt" :limit="limit" @auth="authEvent" @status="statusEvent" />
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="!status" class="btn btn-solid btn-blue" @click="auth">{{ $t('auth') }}</button>
        </div>
        <div class="mt-4">
          <div class="link link-info">
            <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alter } from '../../api/2fa'
import CodeComponent from '@/components/CodeComponent.vue'

export default {
  name: 'google.setting.type.verify.component',
  props: {
    type: String
  },
  components: {
    CodeComponent
  },
  data () {
    return {
      status: false,
      attempt: 0,
      limit: 0
    }
  },
  methods: {
    async authEvent () {
      await this.auth()
    },
    statusEvent (status) {
      this.status = status
    },
    async auth () {
      this.status = false
      const code = this.$refs.child.getCode()
      const ret = await alter(this.type, { code: code })
      if (!ret.status) {
        this.attempt = ret.data.attempt
        this.limit = ret.data.limit
        if (this.limit === this.attempt) {
          this.$router.push({
            name: 'customer.unused'
          })
          return false
        }
        this.$bvModal.show('code-modal')
        this.status = true
        return
      }
      const url = ret.data.redirect
      const tid = ret.data.tid
      const token = this.$store.getters.token
      this.$router.push({
        name: 'setting.success',
        params: {
          mode: true,
          url: url,
          token: token,
          tid: tid
        }
      })
    },
    cancelled () {
      this.$router.push({
        name: 'setting.index'
      })
    },
    lost () {
      this.$router.push({
        name: 'setting.google.reset',
        params: {
          mode: true
        }
      })
    }
  }
}
</script>
