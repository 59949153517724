<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <EmailSettingTypeVerify :type="type" :email="email"/>
      <GoogleSettingTypeVerify :type="type"/>
      <SmsSettingTypeVerify :type="type" :mobile="mobile"/>
    </div>
  </div>
</template>

<script>
import EmailSettingTypeVerify from '@/components/email/EmailSettingTypeVerify.vue'
import GoogleSettingTypeVerify from '@/components/google/GoogleSettingTypeVerify.vue'
import SmsSettingTypeVerify from '@/components/sms/SmsSettingTypeVerify.vue'
import { info } from '../api/2fa'

export default {
  name: 'setting.type.verify',
  components: {
    EmailSettingTypeVerify,
    GoogleSettingTypeVerify,
    SmsSettingTypeVerify
  },
  data () {
    return {
      loading: false,
      email: '',
      type: '',
      mobile: {
        code: '',
        number: ''
      }
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'setting.index'
      })
      return false
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retInfo = await info()
      this.email = retInfo.data.customer.email
      this.mobile = retInfo.data.customer.mobile
      this.type = this.$route.params.type
      this.loading = false
    }
  }
}

</script>
